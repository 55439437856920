import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";
import { IQuizCategory } from "interfaces";

export const QuizCategoryList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<IQuizCategory>({
    initialSorter: [
      {
        field: "quiz_category_id",
        order: "desc",
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="quiz_category_id">
        <Table.Column
          dataIndex="quiz_category_id"
          key="quiz_category_id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("quiz_category_id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("quizCategory.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
        />
        <Table.Column
          dataIndex="id_custom"
          key="id_custom"
          title={t("quizCategory.fields.id_custom")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id_custom", sorter)}
        />
        <Table.Column
          dataIndex="imagen"
          key="imagen"
          title={t("quizCategory.fields.imagen")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("imagen", sorter)}
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title={t("quizCategory.fields.created_at")}
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
        />
        <Table.Column<IQuizCategory>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.quiz_category_id} />
              <ShowButton hideText size="small" recordItemId={record.quiz_category_id} />
              <DeleteButton hideText size="small" recordItemId={record.quiz_category_id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
