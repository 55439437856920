import {
  useTranslate,
  useApiUrl,
  useHandleNotification,
  IResourceComponentsProps,
  CrudFilters,
} from "@pankod/refine-core";
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import type { UploadChangeParam } from 'antd/es/upload';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import {
  Form,
  Button,
  CreateButton,
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
  Upload,
  Input,
  Icons,
} from "@pankod/refine-antd";
import { IQuiz } from "interfaces";
import { useState } from 'react';
import { HttpError } from "@pankod/refine-core";
import DeleteAllButton from "./deleteAllButton";
import { useInvalidate } from "@pankod/refine-core";

export const QuizList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const handleNotification = useHandleNotification();
  const invalidate = useInvalidate();

  const [loading, setLoading] = useState(false);
  const apiUrl = useApiUrl();
  const APIToken = localStorage.getItem("api-token");

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      handleNotification({
        message: "Bulk upload has been successful",
        type: "success",
        key: "bulk-upload-error",
      });

      // Refresh page
      invalidate({
        resource: "quiz",
        invalidates: ["list"],
      });
    }
    if (info.file.status === 'error') {
      setLoading(false);
      console.log(info.file.response)
      handleNotification({
        message: "Error: " + info.file.response.message,
        type: "error",
        key: "bulk-upload-error",
      });
    }
  };

  const { tableProps, sorter, searchFormProps } = useTable<IQuiz, HttpError, { q: string }>({
    initialSorter: [
      {
        field: "quiz_id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
        const filters: CrudFilters = [];
        const { q } = params;

        filters.push(
            {
              field: "q",
              operator: "eq",
              value: q,
            },
        );

        return filters;
    },
  });

  return (
    (<List
      headerProps={{
        extra:[
          <Form layout="vertical" {...searchFormProps}>
            <Form.Item name="q">
                <Input
                    placeholder="Search"
                    prefix={<Icons.SearchOutlined />}
                />
            </Form.Item>
          </Form>,
          <Upload
            accept=".csv"
            showUploadList={false}
            action={`${apiUrl}/quiz/bulk-upload`}
            headers={{Authorization: `Bearer ${APIToken}`}}
            onChange={handleChange}
          >
            <Button icon={loading ? <LoadingOutlined /> : <UploadOutlined />}>Bulk upload</Button>
          </Upload>,
          <CreateButton />,
          <DeleteAllButton apiUrl={apiUrl} APIToken={APIToken} />,
        ]
      }}
    >
      <Table {...tableProps} rowKey="quiz_id">
        <Table.Column
          dataIndex="quiz_id"
          key="quiz_id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("quiz_id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="question"
          key="question"
          title={t("quiz.fields.question")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("question", sorter)}
        />
        <Table.Column
          dataIndex="quiz_category_name"
          key="quiz_category_name"
          title={t("quiz.fields.quiz_category_name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("quiz_category_name", sorter)}
        />
        <Table.Column
          dataIndex="lang"
          key="lang"
          title={t("quiz.fields.lang")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
            dataIndex="correct_perc"
            key="correct_perc"
            title={t("quiz.fields.correct_answers")}
            render={(_, record: { correct_perc: number; correct: number; count: number }) => {
              const { correct_perc, count, correct } = record;
              return <TextField value={`${correct_perc}% (${correct}/${count})`} />;
            }}
            defaultSortOrder={getDefaultSortOrder("correct_perc", sorter)}
            sorter
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title={t("quiz.fields.created_at")}
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("created_at", sorter)}
        />
        <Table.Column<IQuiz>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.quiz_id} />
              <ShowButton hideText size="small" recordItemId={record.quiz_id} />
              <DeleteButton hideText size="small" recordItemId={record.quiz_id} />
            </Space>
          )}
        />
      </Table>
    </List>)
  );
};
