import React from "react";
import { Button, Modal, Form, Input, message } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useInvalidate } from "@pankod/refine-core";
import axios from "axios";

const DeleteAllButton = ({ apiUrl, APIToken }: {apiUrl: string, APIToken: string | null} ) => {
    const { t } = useTranslation();
    const [form] = Form.useForm(); // Form instance
    const invalidate = useInvalidate();

    const showDeleteConfirmation = () => {
        // Reset form fields when opening the modal
        form.resetFields();

        const handleOk = async () => {
            try {
                // Validate form
                await form.validateFields();
                const confirmationText = form.getFieldValue("confirmationText");

                if (confirmationText !== "DELETE ALL") {
                    message.error(t("quiz.delete_all.message.incorrect"));
                    return Promise.reject();
                }

                // Show loading state
                const hideLoading = message.loading("Deleting quizzes, please wait...", 0);

                try {
                    // Make the POST request
                    await axios.post(
                        `${apiUrl}/quiz/delete-all`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${APIToken}`,
                            },
                        }
                    );

                    // Success message
                    message.success(t("quiz.delete_all.message.success"));

                    // Refresh page
                    invalidate({
                        resource: "quiz",
                        invalidates: ["list"],
                    });
                } catch (error) {
                    // Handle error
                    console.error(t("quiz.delete_all.message.error"), error);
                    // Narrow the error type
                    const errorMessage =
                        (axios.isAxiosError(error) && error.response?.data?.message)
                        || "Something went wrong. Please try again.";

                    message.error(errorMessage);
                    return Promise.reject(errorMessage);
                } finally {
                    hideLoading(); // Hide the loading message
                }
            } catch (err) {
                // Validation or request error
                console.error(t("quiz.delete_all.message.error_validation"), err);
                return Promise.reject(err);
            }
        };

        Modal.confirm({
            icon: <ExclamationCircleFilled />,
            title: t("quiz.delete_all.title"),
            content: (
                <Form form={form} layout="vertical">
                    <p>
                        {t("quiz.delete_all.description")}
                    </p>
                    <Form.Item
                        name="confirmationText"
                        label={t("quiz.delete_all.input.label")}
                        rules={[
                            {
                                required: true,
                                message: t("quiz.delete_all.input.rules"),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t("quiz.delete_all.input.placeholder")}
                            autoComplete="off" // Disable autocomplete
                        />
                    </Form.Item>
                </Form>
            ),
            onOk: handleOk,
            onCancel() {
                message.info(t("quiz.delete_all.oncancel"));
            },
        });
    };

    return (
        <Button danger onClick={showDeleteConfirmation}>
            {t("buttons.deleteAll")}
        </Button>
    );
};

export default DeleteAllButton;