import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import detector from "i18next-browser-languagedetector";

const BASE_PATH = process.env.REACT_APP_BASE_PATH || "";

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "de"],
    backend: {
      loadPath: `${BASE_PATH}locales/{{lng}}/{{ns}}.json`,
    },
    defaultNS: "common",
    fallbackLng: ["en", "de"],
  });

export default i18n;
