import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  ShowButton,
} from "@pankod/refine-antd";
import { IGameType } from "interfaces";

export const GameTypeList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<IGameType>({
    initialSorter: [
      {
        field: "game_type_id",
        order: "desc",
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey="game_type_id">
        <Table.Column
          dataIndex="game_type_id"
          key="game_type_id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("game_type_id", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("gameType.fields.name")}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="created_at"
          key="created_at"
          title={t("parameter.fields.created_at")}
          render={(value) => <DateField value={value} format="LLL" />}
        />
        <Table.Column<IGameType>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.game_type_id} />
              <ShowButton hideText size="small" recordItemId={record.game_type_id} />
              <DeleteButton hideText size="small" recordItemId={record.game_type_id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
